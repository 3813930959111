export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/about": [3],
		"/gooaye-EP485-transcription": [4],
		"/gooaye-EP486-transcription": [5],
		"/gooaye-EP487-transcription": [6],
		"/gooaye-EP488-transcription": [7],
		"/gooaye-EP489-transcription": [8],
		"/gooaye-EP490-transcription": [9],
		"/gooaye-EP491-transcription": [10],
		"/gooaye-EP492-transcription": [11],
		"/gooaye-EP493-transcription": [12],
		"/gooaye-EP494-transcription": [13],
		"/gooaye-EP495-transcription": [14],
		"/gooaye-EP496-transcription": [15],
		"/gooaye-EP497-transcript": [16],
		"/gooaye-EP498-transcript": [17],
		"/gooaye-EP499-transcript": [18],
		"/gooaye-EP500-transcript": [19],
		"/gooaye-EP501-transcript": [20],
		"/gooaye-EP502-transcript": [21],
		"/gooaye-EP503-transcript": [22],
		"/gooaye-EP504-transcript": [23],
		"/gooaye-EP505-transcript": [24],
		"/gooaye-EP506-transcript": [25],
		"/legal": [26]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';